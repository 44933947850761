<template>
    <div class="content">
        <h1>Договор-оферта</h1>
        <h2>1. Общие положения</h2>
        <p>1.1. Настоящий Договор является официальным предложением (публичной офертой) ООО «Фишка» (в дальнейшем «Исполнитель») для полностью дееспособного физического лица (далее – «Заказчик»), которое примет настоящее предложение, на указанных ниже условиях.</p>
        <p>1.2. В соответствии с пунктом 2 статьи 437 Гражданского Кодекса Российской Федерации (ГК РФ), в случае принятия изложенных ниже условий и оплаты услуг юридическое или физическое лицо, производящее акцепт этой оферты, становится Заказчиком (в соответствии с пунктом 3 статьи 438 ГК РФ акцепт оферты равносилен заключению Договора на условиях, изложенных в оферте).</p>
        <p>1.3. Моментом полного и безоговорочного принятия Заказчиком предложения Исполнителя заключить Договор оферты (акцептом оферты) считается факт подтверждения готовности совершить оплату услуги Исполнителя, посредством нажатия кнопки «Оплатить» на сайте <a href="https://direct.lradio.ru">https://direct.lradio.ru/</a>. Текст настоящего Договора-оферты (далее по тексту – «Договор») расположен по адресу: <a href="/dogovor">https://direct.lradio.ru/dogovor</a></p>
        <p>1.4. Осуществляя акцепт Договора в порядке, определенном п. 1.3 Договора, Заказчик подтверждает, что он ознакомлен, согласен, полностью и безоговорочно принимает все условия Договора в том виде, в каком они изложены в тексте Договора, в том числе в приложениях к Договору, являющихся его неотъемлемой частью.</p>
        <p>1.5. Клиент согласен, что акцепт Договора в порядке, указанном в п. 1.2 Договора является заключением Договора на условиях, изложенных в нем.</p>
        <p>1.6. Договор не может быть отозван.</p>
        <p>1.7. Договор не требует скрепления печатями и/или подписания Заказчиком и Исполнителем (далее по тексту - Стороны) и сохраняет при этом юридическую силу.</p>

        <h2>2. Предмет договора</h2>
        <p>2.1. Предметом настоящего Договора является возмездное оказание Исполнителем информационных и рекламных услуг, а также услуг автоматизированного расчета статистических критериев в соответствии с условиями настоящего Договора.</p>
        <p>2.2. Заказчик полностью принимает условия Договора и оплачивает услуги Исполнителя в соответствии с условиями настоящего Договора.</p>

        <h2>3. Оплата Услуг</h2>
        <p>3.1. Стоимость услуг по Договору определяется в соответствии с действующими ценами и прописана на сайте:
            <ul>
                <li>Города Екатеринбург, Челябинск - 200 рублей за 1 выход ролика (FM и онлайн).</li>
                <li>Прочие города - 50 рублей за 1 выход ролика (FM и онлайн).</li>
            </ul>
        </p>
        <p>3.2. Стоимость услуги может быть изменена Исполнителем в одностороннем порядке.</p>
        <p>3.3. Способы оплаты услуги указаны при оформлении платежа.</p>

        <h2>4. Интеллектуальная собственность</h2>
        <p>4.1. Вся текстовая информация и графические изображения, находящиеся на сайте <a href="https://direct.lradio.ru">https://direct.lradio.ru/</a> являются собственностью Исполнителя.</p>

        <h2>5. Особые условия и ответственность сторон.</h2>
        <p>5.1. Исполнитель несет ответственность за своевременность предоставляемых услуг при выполнении Заказчиком установленных требований и правил, размещенных на сайте <a href="https://direct.lradio.ru">https://direct.lradio.ru/</a>.</p>
        <p>5.2. Исполнитель освобождается от ответственности за нарушение условий Договора, если такое нарушение вызвано действием обстоятельств непреодолимой силы (форс-мажор), включая: действия органов государственной власти, пожар, наводнение, землетрясение, другие стихийные действия, отсутствие электроэнергии, забастовки, гражданские волнения, беспорядки, военные действия любые иные обстоятельства, не ограничиваясь перечисленным, которые могут повлиять на выполнение Исполнителем Договора.</p>
        <p>5.3. Исполнитель не несет ответственности за качество каналов связи общего пользования или служб, предоставляющих доступ Заказчика к его услугам.</p>

        <h2>6. Конфиденциальность и защита персональной информации</h2>
        <p>6.1. Исполнитель обязуется не разглашать полученную от Заказчика информацию.</p>
        <p>6.2. Не считается нарушением обязательств разглашение информации в соответствии с обоснованными и применимыми требованиями закона.</p>
        <p>6.3. Исполнитель получает информацию об IP-адресе и email посетителя сайта <a href="https://direct.lradio.ru">https://direct.lradio.ru/</a>. Данная информация не используется для установления личности посетителя.</p>
        <p>6.4. Исполнитель не несет ответственности за сведения, предоставленные Заказчиком на сайте <a href="https://direct.lradio.ru">https://direct.lradio.ru/</a> в общедоступной форме.</p>

        <h2>7. Порядок рассмотрения претензий и споров</h2>
        <p>7.1. Претензии Заказчика по предоставляемым услугам принимаются Исполнителем к рассмотрению по электронной почте <a href="mailto:olegmakhalov@gmail.com">olegmakhalov@gmail.com</a> в течение 2 (рабочих) дней с момента возникновения спорной ситуации.</p>
        <p>7.2. При рассмотрении спорных ситуаций Исполнитель вправе запросить у Заказчика всю интересующую документацию относительно рассматриваемого мероприятия. В случае непредоставления Заказчиком документов в течение 1 рабочего дня после дня требования, претензия рассмотрению Исполнителем не подлежит.</p>
        <p>7.3. Исполнитель и Заказчик, принимая во внимания характер оказываемой услуги, обязуются в случае возникновения споров и разногласий, связанных с оказанием услуг, применять досудебный порядок урегулирования спора. В случае невозможности урегулирования спора в досудебном порядке стороны вправе обратиться в суд.</p>

        <h2>8. Прочие условия</h2>
        <p>8.1. Заказчик обладает всеми правами и полномочиями, необходимыми для заключения и исполнения Договора.</p>
        <p>8.2. Заказчик вправе в любое время в одностороннем порядке отказаться от услуг Исполнителя. В случае одностороннего отказа Заказчика от услуг Исполнителя произведенная оплата не возвращается, но может быть перенесена на другую услугу.</p>
        <p>8.3. Исполнитель оставляет за собой право изменять или дополнять любые из условий настоящего Договора в любое время, опубликовывая все изменения на своем сайте.</p>
        <p>8.4. По всем вопросам, не урегулированным настоящим Договором, стороны руководствуются действующим законодательством Российской Федерации.</p>
        <p>8.5. Признание судом недействительности какого-либо положения настоящего Договора и правил не влечет за собой недействительность остальных положений.</p>

        <h2>9. Реквизиты Исполнителя</h2>
        <h3>ООО «Фишка»</h3>
        <p>
            Адрес: 454091, г. Челябинск, ул. Орджоникидзе, 41-84<br>
            тел./факс: 8(351)700-00-07<br>
            ИНН/КПП 7453215092/745101001<br>
            Р/с 40702810938040001028<br>
            в филиале «Екатеринбургский» АО «АЛЬФА-БАНК»<br>
            БИК 046577964<br>
            Директор Махалов Олег Владимирович
        </p>

    </div>
</template>

<script>
    export default {
        name: 'Offer',
        created() {
        },
        computed: {
        },
        props: {
        },
        data() {
            return {};
        },
        methods: {
        }
    };
</script>
