import {app as settings} from '@/settings';
import {REST, RESTError} from './rest';

export default class extends REST {
    static get settings() {
        return settings;
    }

    static obtainToken(params) {
        return this._post(`token/create`, {}, params).then((data) => {
            let tokens = data;
            return tokens;
        }).catch(() => {
           return {}
        });
    }

    static refreshToken(token) {
        return this._post(`token/refresh`, {}, {refresh: token}).then((data) => {
            let tokens = data;
            return tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновит токен');
        });
    }

    static createUser(params) {
        return this._post(`user/create_user`, {}, params).then((data) => {
            let tokens = data;
            return tokens;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось создать пользователя');
        });
    }

    static updateUser(params) {
        return this._post(`user/update_user`, {}, params).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось обновить данные пользователя');
        });
    }

    static resetUser(params) {
        return this._post(`user/password_reset_user`, {}, params).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось сбросить пароль пользователя');
        });
    }

    static getUser() {
        return this._get(`user`, {}).then((data) => {
            let user = data;
            return user;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить пользователя');
        });
    }

    static getUserCampaigns(pagination) {
        return this._get(`direct/user_campaign/user_campaign/limit=${pagination.limit}/offset=${pagination.offset}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить кампании');
        });
    }

    static getUserCampaignsAllTypes() {
        return this._get(`direct/short_campaign_type`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить типы');
        });
    }

    static getUserCampaignsForTypes(id, pagination) {
        return this._get(`direct/user_campaign/by_type/${id}/limit=${pagination.limit}/offset=${pagination.offset}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить кампании');
        });
    }

    static getRegions() {
        return this._get(`direct/locations`, {}, false).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить регионы трансляции');
        });
    }

    static getRegionsByType(type) {
        return this._get(`direct/locations/by_type/${type}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить регионы трансляции');
        });
    }

    static getAdsFile(id) {
        return this._get(`direct/files/${id}`, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить файл ролика');
        });
    }

    static deleteAdsFile(id) {
        return this._delete(`direct/files/${id}`, {}, {}).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось удалить файл ролика');
        });
    }

    static checkPromocode(id, promocode) {
        let params = Object.assign({}, {id: id, promo_code: promocode});
        return this._post(`direct/promo/get_promo`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось проверить промокод');
        });
    }

    static getCamaignType() {
        return this._get(`direct/campaign_type`, {}, false).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить типы кампаний');
        });
    }


    static sendAdsInfo(campaign) {
        let params = Object.assign({}, campaign);
        return this._post(`direct/campaing/text_camp`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось отправить запрос на создание ролика');
        });
    }

    static submitCampaign(id) {
        let params = Object.assign({}, {camp_id: id});
        return this._post(`direct/campaign_type/create_camp`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось сохранить рекламную кампанию');
        });
    }
    static submitCampaignEntity(id, user) {
        let params = Object.assign({}, {camp_id: id, ...user});
        return this._post(`direct/contract/contract`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось сохранить рекламную кампанию');
        });
    }
    static amountCampaign(campaign) {
        let params = Object.assign({}, campaign);
        //console.log(campaign, params);
        return this._post(`direct/campaing/amount`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить стоимость кампании');
        });
    }

    static checkPayment(params) {
        return this._post(`direct/banking/robokassa/payment/check_success_payment`, {}, params).then((data) => {
            return data;
        }).catch((error) => {
            throw new RESTError(error, 'Не удалось получить стоимость кампании');
        });
    }
}
