import {createApp} from 'vue'

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css';
import '@splidejs/vue-splide/css';
// import * as Sentry from "@sentry/vue";
import { sentry_dns } from '@/settings';
import BootstrapVue3 from 'bootstrap-vue-3';
import VueUploadComponent from 'vue-upload-component';
import {vMaska} from 'maska';

import router from './router/router';
import store from './store/store';
import helpers from './utils/helpers';

import App from './App.vue'
import * as Sentry from '@sentry/vue';
const app = createApp(App);

Sentry.init({
    app,
    dsn: sentry_dns,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
app.use(BootstrapVue3);
app.directive('maska', vMaska);
app.use(store);
app.use(router);
app.component('file-upload', VueUploadComponent);
app.use(helpers);

app.mount('#app');
