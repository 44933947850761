<template>
    <div
        :class="`campaigns__item  row mb-4 ${campaign.ads_type==='text'||campaign.ads_type==='idea'?'pb-3':'pb-2'}`"
    >
        <div class="col-12 col-xxl-5">
            <b-card
                body-class="d-flex flex-column"
                class="campaigns__item-info border-warning mb-3"
            >
                <div class="campaigns__item-title text-white">
                    <div class="d-flex flex-column">
                        {{ getFormattedDate(campaign.created) }} — Кампания №{{ campaign.id }}
                        <span class="campaigns__item-title-span">{{getTypeCampaign(campaign)}}</span>
                    </div>
                    <div class="campaigns__item-title-label">{{ getStatusCampaign(campaign) }}</div>
                </div>
                <div class="campaigns__item-content">
                    <p>{{ campaign.message }}</p>
                </div>
                <div v-if="campaign.ads_type==='audio'||campaign.ads_type==='personal'"
                     class="campaigns__item-content">
                    <div
                        v-if="campaign.campaign_schedule_data[0]?.campaign_start&&campaign.campaign_schedule_data[0]?.campaign_end"
                        class="campaigns__item-content-block">
                        <p><span>Даты проведения кампании:</span></p>
                        <p v-for="date in campaign.campaign_schedule_data"
                           :key="date.campaign_start +date.campaign_end">
                            {{
                                getFormattedDate(date?.campaign_start) + ' - ' + getFormattedDate(date?.campaign_end)
                            }}
                        </p>
                    </div>
                    <div class="campaigns__item-content-block">
                        <p><span>Регионы:</span> {{ getRegions(campaign.regions) }}</p>
                    </div>
                    <div v-if="campaign.time_schedule_data[0]&&campaign.ads_type==='personal'"
                         class="campaigns__item-content-block">
                        <p><span>Время показов:</span> {{ getShowTime(campaign.time_period) }}</p>
                    </div>
                    <div class="campaigns__item-content-block">
                        <p><span>Стоимость показов:</span>
                            {{totalPriceFormat}}
                            ₽ (выходов рекламы:
                            {{ campaign.count_view }} )</p>
                    </div>
                </div>
                <div class="campaigns__item-content">
                    <template v-if="campaign.status.toLowerCase()==='waiting_pay'">
                        <div class="campaigns__item-promo">
                            <b-form
                                class="form mb-3"
                                @submit="onSubmitPromo"
                            >
                                <div class="row">
                                    <b-form-group
                                        id="select-group-promo"
                                        class="col-12 col-sm-7 mt-auto"
                                        label="Промокод"
                                    >
                                        <b-form-input
                                            id="select-promo"
                                            v-model="campaign.promocode.promo_code"
                                            name="promocode"
                                            size="lg"
                                            :placeholder="campaign?.promocode?.error || campaign.promocode.promo_code"
                                            :disabled="!isChangePromoCode || showLoaderSending"
                                            :state="campaign.promocode?.error && !isChangePromoCode ? false : null"
                                        ></b-form-input>
                                    </b-form-group>
                                    <b-form-group
                                        id="select-group-promo-submit"
                                        class="col-12 col-sm-5 col-md-5 ms-auto mt-auto"
                                    >
                                        <b-overlay
                                            :show="showLoaderSending"
                                            rounded
                                            spinner-small
                                            spinner-variant="warning"
                                            class="d-block"
                                        >
                                            <b-button
                                                type="submit"
                                                variant="outline-warning"
                                                size="lg"
                                                class="w-100 text-white"
                                                @click="changePromoCode"
                                                :disabled="showLoaderSending"
                                            >
                                                {{ !isChangePromoCode ? 'Изменить' : 'Применить' }}
                                            </b-button>
                                        </b-overlay>
                                    </b-form-group>
                                </div>
                            </b-form>
                        </div>
                        <div class="campaigns__item-payment">
                            <div class="campaigns__item-payment-sum">
                                    <span>К оплате: <strong
                                        class="text-nowrap">{{totalPriceFormat }}</strong> ₽</span>
                            </div>
                        </div>
                        <b-overlay
                            :show="showLoaderSending"
                            rounded
                            spinner-small
                            spinner-variant="warning"
                            class="d-block pt-3"
                        >
                            <b-button
                                type="submit"
                                variant="warning"
                                class="d-block col-12"
                                size="lg"
                                :disabled="showLoaderSending"
                                @click="onSubmitPay"
                            >
                                Оплатить
                            </b-button>
                        </b-overlay>
                    </template>
                    <template v-else-if="campaign.status.toLowerCase()!=='new'">
                        <div class="row">
                            <h5 class="col-6"><span>Оплачено: </span>
                                {{
                                    totalPriceFormat
                                }} ₽</h5>
                            <p class="col-6 text-warning" v-if="campaign.promocode?.promo_code!==''">
                                <small>
                                    скидка по промокоду
                                    {{ campaign.promocode?.promo_code }}
                                </small>
                            </p>
                        </div>
                    </template>
                    <div v-if="campaign.comment" class="campaigns__item-content-block mt-3">
                        <p><span>Комментарий L-Direct</span></p>
                        <p>{{ campaign.comment }}</p>
                    </div>
                </div>
            </b-card>
        </div>
        <div v-if="campaign.ads_type==='audio'&& campaign.time_schedule" class="col-12 col-xxl-7">
            <b-card
                body-class="d-flex flex-column"
                class="campaigns__item-schedule border-warning mb-3"
            >
                <div class="campaigns__item-content">
                    <p><span>График показов</span></p>
                </div>
                <timeTable
                    :show="true"
                    :idKey="campaign.id"
                    :disabledControl="true"
                    :scheduleData="campaign.time_schedule_data"
                />
            </b-card>
        </div>
    </div>
</template>

<script>
import timeTable from "@/components/timeTable.vue";
import {app} from "@/services";

export default {
    components: {
        timeTable
    },
    data() {
        return {
            showLoaderSending: false,
            showLoaderCamping: false,
            campaign: {},
            isChangePromoCode: false
        }
    },
    props: {
        campaignData: {
            type: Object
        }
    },
    methods: {
        getFormattedDate(dateString) {
            return this.$helpers.formatDate(new Date(dateString), 'DD.MM.YY');
        },
        getRegions(array, allId = false) {
            if (allId) {
                return array?.map(item => item.id);
            }
            return array?.map(item => {
                return item.regions
            }).join(', ')

        },
        getShowTime(array) {
            return JSON.parse(array)?.map(item => {
                switch (item) {
                    case 'morning':
                        return 'Утро';
                    case 'daytime':
                        return 'День';
                    case 'evening':
                        return 'Вечер';
                }
            }).join(', ');
        },
        onSubmitPromo() {
            if (!this.isChangePromoCode) {
                app.checkPromocode(this.campaign.id, this.campaign.promocode.promo_code).then(res => {
                    if (res.error) {
                        this.campaign.promocode = res;
                    } else {
                        this.campaign = {...res, regions: this.campaign.regions};
                    }
                }).catch(err => {
                    this.$store.dispatch('showError', err);
                }).finally(() => {
                    this.showLoaderSending = false;
                });
            }
        },
        getTypeCampaign(campaign) {
            if (campaign.ads_type.toLowerCase() === "audio") return "Аудио"
            if (campaign.ads_type.toLowerCase() === "text") return "Текст"
            if (campaign.ads_type.toLowerCase() === "idea") return "Идея"
            else return 'Объявление'
        },
        getStatusCampaign(campaign) {
            if (campaign.status.toLowerCase() === "waiting_pay") return "Ожидает оплаты"
            if (campaign.status.toLowerCase() === "paid") return "Оплачено"
            if (campaign.status.toLowerCase() === "in_progress") return "В процессе"
            if (campaign.status.toLowerCase() === "on_air") return "В эфире"
            if (campaign.status.toLowerCase() === "completed") return "Завершено"
            if (campaign.status.toLowerCase() === "new") return "Новый"
            else return 'Опубликовано'
        },
        changePromoCode() {
            this.isChangePromoCode = !this.isChangePromoCode;
        },
        onSubmitPay() {
            app.submitCampaign(this.campaign.id).then(res => {
                this.showLoaderSending = false;
                window.location.href = res.url
            }).catch(err => {
                this.showLoaderSending = false;
                this.$store.dispatch('showError', err);
            });
        },
    },
    computed: {
        totalPriceFormat() {
            return this.$helpers.toPrice(
                this.campaign.campaign_amount.total_amount === 0 ?
                    this.campaign.campaign_amount.amount :
                    this.campaign.campaign_amount.total_amount
            );
        }
    },
    created() {
        this.campaign = this.campaignData;
    }
}
</script>