<template>
    <div class="campaign__ads">
        <div
            class="campaign__ads-block mb-4 row"
        >
            <div
                v-for="type in camaignTypes"
                :key="type.id+type.type"
                class="col-12 col-md-6 col-lg-6 mb-4"
                @click="selectAdsType(type.type)"
            >
                <b-card
                    body-class="d-flex flex-column"
                    :class="campaign.ads_type === type.type ? 'is-active' : ''"
                    class="campaign__ads-item h-100"
                >
                    <b-card-title
                        class="text-uppercase"
                    >
                        {{ type.title }}
                    </b-card-title>
                    <div class="flex-grow-1">
                        <b-card-text
                            class="pt-0 m-0"
                            v-for="descr in type.description?.split('\n')"
                            :key="descr"
                        >
                            {{ descr }} <br/>
                        </b-card-text>
                    </div>
                    <div>
                        <b-card-text
                            class="pt-3 mb-1"
							v-if="type.making_price"
                        >
                            Изготовление ролика: <span class="text-warning">{{type.making_price}}</span>
                        </b-card-text>
                        <b-card-text
                            v-if="type.display_price"
                            class="pt-1"
                        >
                            Стоимость показов: <span class="text-warning">{{type.display_price}}</span>
                        </b-card-text>
                    </div>
                </b-card>
            </div>
        </div>
        <div
            v-if="campaign.ads_type != null"
            class="campaign__ads-form"
        >
            <b-form
                class="form"
                @submit="onSubmit"
                v-for="type in camaignTypes"
                :key="type.id+type.type"
            >
                <b-card
                    body-class="d-flex flex-column"
                    class="card-border position-relative overflow-hidden"
                    v-if="campaign.ads_type===type.type"
                >
                    <div class="card campaign__ads-description">
                        <strong>{{type.new_title || 'Оставьте данные, чтобы менеджер мог связаться с вами'}}</strong>
											{{type.text || 'Настройте кампанию со специалистом L-direct'}}
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <b-form-group
                                id="input-group-person"
                                class="w-100 mt-auto required"
                                label="Ваше имя"
                            >
                                <b-form-input
                                    id="input-person"
                                    v-model="campaign.first_name"
                                    required
                                    size="lg"
                                ></b-form-input>
                            </b-form-group>

                            <b-form-group
                                id="select-group-phone"
                                class="w-100 mt-auto required"
                                label="Ваш телефон"
                            >
                                <input
                                    class="form-control form-control-lg"
                                    type="text"
                                    v-maska
                                    required
                                    placeholder="+7 XXX XXX-XX-XX"
                                    data-maska="+7 ### ###-##-##"
                                    v-model="campaign.phone"
                                />
                            </b-form-group>
                        </div>
                        <div class="col-12 col-md-6">
                            <b-form-group
                                id="input-group-message"
                                class="w-100 mt-auto required"
                                :label="type.details||'Ваш комментарий'"
                            >
                                <b-form-textarea
                                    id="input-message"
                                    v-model="campaign.message"
                                    required
                                    size="lg"
                                ></b-form-textarea>
                            </b-form-group>
                        </div>
                    </div>
                    <div class="d-flex mb-4">
                        <b-form-checkbox
                            name="agreement"
                            v-model="processingAgreement"
                            required
                        >
                            <small>Я <a class="text-warning" href="#" @click.prevent="showPolicy">ознакомлен и согласен</a> с условиями
                                политики
                                обработки персональных данных и конфиденциальности.</small>
                        </b-form-checkbox>
                    </div>
                    <b-overlay
                        :show="showLoaderSending"
                        no-wrap
                        spinner-variant="warning"
                    />
                    <b-button
                        type="submit"
                        variant="warning"
                        class="d-block col-12"
                        size="lg"
                        v-if="this.checkIsNextStep()"
                    >
                        Создать
                    </b-button>
                    <b-button
                        type="submit"
                        variant="warning"
                        class="d-block col-12"
                        size="lg"
                        v-else
                    >
                        Продолжить
                    </b-button>
                </b-card>
            </b-form>
        </div>
        <CampaignNew
            :show="showModalCampaignNew"
            @hideCampaignNew="hideCampaignNew"
        />
        <Policy
            :show="showModalPolicy"
            @hidePolicy="hidePolicy"
        />
    </div>
</template>

<script>
import {app} from "@/services";
import CampaignNew from "@/components/campaign-new";
import Policy from "@/components/policy";

export default {
    name: 'campaignAds',
    components: {
        CampaignNew,
        Policy
    },
    props: {
        step: {
            type: Number,
            default() {
                return null;
            }
        }
    },
    data() {
        return {
            campaign: null,
            showModalPolicy: false,
            showModalCampaignNew: false,
            showPrice: false,
            camaignTypes: [],
            showLoaderSending: false,
            showLoaderType: false,
            processingAgreement: true,
						user: null
        };
    },
    created() {
			this.user = this.$store.state.user
        if (!this.$store.state.campaign?.camp_id){
            this.$store.dispatch('clearCampaign');
        }
        this.campaign = this.$helpers.removeKeys({...this.$store.state.campaign, ...this.user }, 'id') ;
        this.bearerToken = this.$store.state.access;
        this.showLoaderType = true;
        if (this.campaign.regions.length > 0 || this.campaign.campaign_schedule_data.length > 0 && this.campaign.campaign_schedule_data[0]?.campaign_start) {
            this.showModalCampaignNew = true;
        }
        app.getCamaignType().then(res => {
            this.camaignTypes = res;
            this.showLoaderType = false
        });
    },
    methods: {
        selectAdsType(type) {
            if (this.showLoaderSending) {
                return;
            }
            this.campaign.ads_type = type;

        },
        hideCampaignNew(confirm = false) {
            this.showModalCampaignNew = false;
            if (confirm) {
                this.$store.dispatch('clearCampaign');
                this.campaign = this.$helpers.removeKeys({...this.$store.state.campaign, ...this.user }, 'id');
            } else {
                this.campaign = this.$store.state.campaign;
                let nextViews = ''
                switch (this.campaign.step) {
                    case 1:
                        nextViews = 'campaignAds'
                        break;
                    case 2:
                        nextViews = 'campaignProps'
                        break;
                    case 3:
                        nextViews = 'campaignPay'
                        break;
                    case 4:
                        nextViews = 'campaignFinish'
                        break;
                }
                this.next(nextViews);
            }
        },
        showPolicy() {
            this.showModalPolicy = true;
        },
        hidePolicy() {
            this.showModalPolicy = false;
        },
        checkIsNextStep() {
            return this.campaign.ads_type === 'text' || this.campaign.ads_type === 'idea'
        },
        onSubmit() {

            if (this.checkIsNextStep() && this.campaign.phone?.replace(/ /g, '').replace(/-/g, '').length > 11) {
                this.showLoaderSending = true;
                app.sendAdsInfo(this.$helpers.removeKeys({
                    ...this.campaign,
                    phone: this.campaign.phone?.replace(/ /g, '').replace(/-/g, ''),
                    status: 'new',
                }, ['step'])).then(() => {
                    this.$store.dispatch('clearCampaign');
                    this.campaign = this.$store.state.campaign;
                    this.next('campaignFinish');
                }).catch(err => {
                    this.showLoaderSending = false;
                    this.$store.dispatch('showError', err);
                }).finally(() => {
                    this.showLoaderSending = false;
                })
            } else if (this.campaign.phone?.replace(/ /g, '').replace(/-/g, '').length > 11) {
                this.$store.dispatch('setCampaignStep', {campaign_step: 3});
                this.$store.dispatch('updateCampaign', {
                    campaign: {
                        ...this.campaign,
                        phone: this.campaign.phone?.replace(/ /g, '').replace(/-/g, ''),
                        step: 2,
                    }
                });
                this.next();
            }
        },
        next(name) {
            this.$router.push({name: name || 'campaignProps'});
        },
    }
}
;
</script>
