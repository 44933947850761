<template>
    <div class="alert border-warning m-auto text-center px-4 py-5">
        <div class="h1 pt-5">Данный сервис находится в разработке</div>
        <div class="h2 pb-5">Надеемся, что скоро вы сможете им воспльзоваться!</div>
    </div>
</template>

<script>
    export default {
        name: 'inDeveloping',
        props: {
        }
    }
</script>
