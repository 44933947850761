<template>
	
	<div class="campaign__pay">
		<div>
			<label class="h5 mb-3"><strong>Итоговая стоимость</strong></label>
			<b-card
				class="mb-5 border-0"
			>
				<div
					class="h2 text-uppercase m-0"
				>
					<strong><span class="campaign__price h4" v-if="campaign.campaign_amount?.total_amount!==0&&campaign.campaign_amount?.total_amount!==campaign.campaign_amount?.amount">
                        <s>
                            {{
								totalPriceFormat(campaign.campaign_amount?.amount)
							}}
                        </s>
                        - </span>
						{{
							totalPriceFormat(this.campaign.campaign_amount?.total_amount || this.campaign.campaign_amount?.amount)
						}} ₽</strong>
				</div>
			</b-card>
		</div>
		<template v-if="!isShowEntityForm">
			<b-form
				class="form mb-3"
				@submit="onSubmitPromo"
			>
				<div class="row">
					<b-form-group
						id="select-group-promo"
						class="col-12 col-sm-7 mt-auto"
						label="Промокод"
					>
						<b-form-input
							id="select-promo"
							v-model="campaign.promocode.promo_code"
							name="promocode"
							size="lg"
							:placeholder="campaign.promocode?.error "
							:disabled="showLoaderSending || showLoaderPromocode || !isChangePromo"
							:state="campaign.promocode?.error && !isChangePromo ? false : null"
						></b-form-input>
					</b-form-group>
					<b-form-group
						id="select-group-promo-submit"
						class="col-12 col-sm-5 col-md-4 ms-auto mt-auto"
					>
						<b-overlay
							:show="showLoaderPromocode"
							rounded
							spinner-small
							spinner-variant="warning"
							class="d-block"
						>
							<b-button
								type="submit"
								variant="outline-warning"
								size="lg"
								class="w-100"
								:disabled="showLoaderSending"
							>
								{{ !isChangePromo ? 'Изменить' : 'Применить' }}
							</b-button>
						</b-overlay>
					</b-form-group>
				</div>
			</b-form>
			
			<b-form
				class="form position-relative"
			>
				<div class="row" v-if="showNotification">
					<label class="form-label"><strong>Уведомления</strong></label>
					<b-form-group
						id="select-group-email"
						class="col-12 col-sm-7 mt-auto"
						label="Адрес для почтовых уведомлений"
					>
						<b-form-input
							id="select-email"
							v-model="campaign.notification_email"
							name="notification_email"
							type="email"
							placeholder="mail@yandex.ru"
							size="lg"
							:disabled="showLoaderSending || showLoaderPromocode"
						></b-form-input>
					</b-form-group>
				</div>
				
				<div
					v-show="showNotification"
					class="row mb-2"
				>
					<div class="col-12">
						<b-form-checkbox
							v-model="campaign.notification_moderate"
							name="notification_modearte"
							switch
							:disabled="showLoaderSending || showLoaderPromocode"
						>
							Модерация рекламной кампании
						</b-form-checkbox>
					</div>
				</div>
				
				<div
					v-show="showNotification"
					class="row"
				>
					<div class="col-12">
						<b-form-checkbox
							v-model="campaign.notification_campaign_end"
							name="notification_campaign_end"
							switch
							:disabled="showLoaderSending || showLoaderPromocode"
						>
							Окончание рекламной кампании
						</b-form-checkbox>
					</div>
				</div>
				<label class="h4 mb-3 mt-4"><strong>Способы оплаты</strong></label>
				<div class="row">
					<div class="col-12 col-sm-6 mb-3">
						<b-card class="campaign__ads-item ">
							<p class="h4 fw-normal">ROBOKASSA для физ. лиц</p>
							<div>Оплата по номеру карты, Яндекс Pay, МТС Pay, СБП. Возможна оплата по частям.</div>
							<b-overlay
								:show="showLoaderSending"
								rounded
								spinner-small
								spinner-variant="warning"
								class="d-block"
							>
								<b-button
									type="submit"
									variant="warning"
									class="d-block col-12 mt-5"
									size="lg"
									:disabled="showLoaderPromocode"
									@click="onSubmitIndividual"
								>
									Оплатить
								</b-button>
							</b-overlay>
						</b-card>
					</div>
					<div class="col-12 col-sm-6 mb-3">
						<b-card class="campaign__ads-item">
							<p class="h4 fw-normal">Счет для юридических лиц</p>
							<div>Выставим счет на вашу организацию. Нужно только указать название организации, ИНН и КПП.</div>
							<b-overlay
								:show="showLoaderSending"
								rounded
								spinner-small
								spinner-variant="warning"
								class="d-block"
							>
								<b-button
									type="submit"
									variant="warning"
									class="d-block col-12 mt-5"
									size="lg"
									:disabled="showLoaderPromocode"
									@click="changeShowEntityForm"
								>
									Оплатить
								</b-button>
							</b-overlay>
						</b-card>
					</div>
				</div>
			</b-form>
		</template>
		<template v-else>
			<h2 class="profile__title mb-2">Юридическая информация</h2>
			<div class="mb-4">для оплаты рекламы по расчетному счету от юр. лица</div>
			<b-form class="form mb-5"
					@submit="onSubmitEntity"
			>
				<b-form-group
					class="w-100 mt-auto required"
					label="Название организации"
				>
					<b-form-input
						required
						size="lg"
						v-model="user.organization"
					></b-form-input>
				</b-form-group>
				<b-form-group
					class="w-100 mt-auto required"
					label="Юридический адрес"
				>
					<b-form-input
						v-model="user.urid_adress"
						required
						size="lg"
					></b-form-input>
				</b-form-group>
				<div class="row align-items-start">
					<b-form-group
						class="col-6 required"
						label="ИНН"
					>
						<b-form-input
							required
							size="lg"
							v-model="user.INN_us"
							:state="formErrorINN ? false : null"
						></b-form-input>
						<b-form-invalid-feedback :state="!formErrorINN" id="input-INN-feedback">
							ИНН должен состоять из 10 или 12 символов
						</b-form-invalid-feedback>
					</b-form-group>
					<b-form-group
						class="col-6 required"
						label="Почта для отправки счета"
					>
						<b-form-input
							v-model="user.email"
							required
							size="lg"
						></b-form-input>
					</b-form-group>
				</div>
				
					<b-button
						type="submit"
						variant="warning"
						class="d-block col-12"
						size="lg"
					>
						Сохранить информацию и скачать счет
					</b-button>
				<b-overlay
					:show="showLoaderSending"
					no-wrap
					spinner-variant="warning"
				/>
			</b-form>
		</template>
	</div>
</template>

<script>
import {app} from "@/services";

export default {
	name: 'campaignPay',
	components: {},
	props: {},
	data() {
		return {
			user: this.$store.state.user,
			showLoaderPromocode: false,
			showLoaderSending: false,
			campaign: null,
			showNotification: false,
			isChangePromo: false,
			isShowEntityForm: false,
			formErrorINN: false
		};
	},
	watch:{
		'user.INN_us': {
			immediate: false,
			handler() {
				if (this.formErrorINN){
					this.formErrorINN = false;
				}
			}
		}
	},
	computed: {},
	created() {
		this.campaign = this.$store.state.campaign;
		if (!this.campaign.notification_email) {
			this.campaign.notification_email = this.$store.state.user.email;
		}
		this.isChangePromo = this.campaign.promocode?.promo_code === '' || false;
		this.$nextTick(() => {
			if (this.campaign.promocode?.promo_code !== "") {
				this.checkPromo(this.campaign.id, this.campaign.promocode?.promo_code)
			}
		});
		if (!this.campaign.ads_type) {
			this.$router.push({name: 'campaign'});
		}
	},
	methods: {
		onSubmitPromo() {
			if (!this.isChangePromo) {
				this.isChangePromo = true;
			} else {
				this.showLoaderPromocode = true;
				this.isChangePromo = false;
				this.checkPromo(this.campaign.id, this.campaign.promocode.promo_code);
			}
		},
		onSubmitIndividual() {
			this.showLoaderSending = true;
			this.$store.dispatch('updateCampaign', {campaign: this.campaign});
			this.$store.dispatch('setCampaignStep', {campaign_step: 4});
			app.submitCampaign(this.campaign.id).then(res => {
				this.showLoaderSending = false;
				window.location.href = res.url
			}).catch(err => {
				this.showLoaderSending = false;
				this.$store.dispatch('showError', err);
			});
		},
		changeShowEntityForm() {
			this.isShowEntityForm = !this.isShowEntityForm;
		},
		onSubmitEntity() {
			this.$store.dispatch('setUser', this.user);
			if(this.user.INN_us.length !== 10 && this.user.INN_us.length !== 12) {
				this.formErrorINN = true;
			}
			if (!this.formErrorINN){
				this.showLoaderSending = true;
				app.submitCampaignEntity(this.campaign.camp_id, {...this.user, phone: this.user.phone?.replace(/ /g, '').replace(/-/g, '')}).then(res => {
					this.showLoaderSending = false;
					this.download(res)
					this.next()
				}).catch(err => {
					this.showLoaderSending = false;
					this.$store.dispatch('showError', err);
				});
			}
		},
		download (url) {
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', url);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},
		next() {
			this.$router.push({name: 'campaignFinish', params: {completed: true}});
		},
		checkPromo(id, code) {
			if (id) {
				app.checkPromocode(id, code).then(res => {
					this.showLoaderPromocode = false;
					if (res.error) {
						this.campaign.promocode = res
					} else {
						this.campaign = {
							...res,
							...this.campaign,
							promocode: res.promocode,
							time_period: this.campaign.time_period,
							campaign_amount: res.campaign_amount,
						}
					}
					this.setPromo(res)
				}).catch(err => {
					this.showLoaderPromocode = false;
					this.$store.dispatch('showError', err);
				});
			} else {
				this.showLoaderPromocode = false;
				this.setPromo()
			}
		},
		setPromo() {
			this.$store.dispatch('updateCampaign', {campaign: this.campaign});
		},
		totalPriceFormat(price) {
			return this.$helpers.toPrice(price);
		}
	}
};
</script>
