<template>
    <topBar/>
    <div class="app__block capmaigns d-flex w-100 flex-row">
        <sideBar/>
        <div class="app__main campaigns pb-5">
            <h1 class="campaigns__title mb-5">Мои кампании</h1>
            <div class="campaigns__filters mb-5">
                <b-button
                    :variant="currentFilter === 'all' ? 'warning' : 'outline-warning'"
                    class="campaigns__filters-item me-4 mb-4"
                    @click.prevent="changeFilter('all')"
                >
                    Все кампании
                </b-button>
                <b-button
                    v-for="(item,index) in campaignsFilters"
                    :key="`filter-${item.type+index}`"
                    :variant="currentFilter === item.type ? 'warning' : 'outline-warning'"
                    class="campaigns__filters-item me-4 mb-4"
                    @click.prevent="changeFilter(item.type)"
                >
                    {{ item.rus_type }}
                </b-button>
            </div>
            <div v-if="showLoaderCamping" class="w-75 d-flex justify-content-center mt-5">
                <b-spinner class="text-warning">
                    <span class="visually-hidden ">Loading...</span>
                </b-spinner>
            </div>
            <div class="campaigns__list" v-else-if="campaigns.length>0">
                <div class="d-flex w-100">
                    <b-pagination
                        v-if="pagination.totalCount>campaigns.length"
                        class="campaigns__pagination my-4"
                        v-model="pagination.offset"
                        :total-rows="pagination.totalCount"
                        :per-page="pagination.limit"
                    ></b-pagination>
                </div>
                <CampaignItem
                    v-for="campaign in campaigns"
                    :key="campaign.id"
                    :campaignData="campaign"
                />
                <div class="d-flex w-100">
                    <b-pagination
                        v-if="pagination.totalCount>campaigns.length"
                        class="campaigns__pagination my-4"
                        v-model="pagination.offset"
                        :total-rows="pagination.totalCount"
                        :per-page="pagination.limit"
                    ></b-pagination>
                </div>
            </div>
            <div v-else class="alert  border-warning">
                <p>Вы ещё не разместили ни одной рекламной кампании.</p>
                <b-button
                    variant="warning"
                    @click="$router.push({ name: 'campaign' })"
                >
									Создать кампанию
                </b-button>
            </div>
        </div>
    </div>
</template>

<script>
import {app} from "@/services";
import topBar from '@/components/topBar';
import sideBar from '@/components/sideBar';
import CampaignItem from "@/components/campaign-item.vue";

export default {
    name: 'capmaigns',
    components: {
        CampaignItem,
        topBar,
        sideBar,
    },
    props: {},
    data() {
        return {
            campaignsFilters: [],
            currentFilter: 'all',
            campaigns: [],
            showLoaderCamping: true,
            pagination: {
                offset: 1,
                limit: 10,
                totalCount: 0
            }
        };
    },
    watch: {
        currentFilter(value) {
            if (value === 'all') {
                this.getCampaigns();
            } else {
                this.getCampaignsTypesForUser();
            }
        },
        "pagination.offset": {
            handler() {
                if (this.currentFilter === 'all'){
                    this.getCampaigns();
                }else {
                    this.getCampaignsTypesForUser();
                }
            }
        },
    },
    computed: {},
    created() {
        this.getCampaigns();
        this.getCampaignsTypes();
    },
    methods: {
        getCampaigns() {
            this.showLoaderCamping = true;
            app.getUserCampaigns(this.pagination).then(data => {
                    this.pagination.totalCount = data.count;
                    this.campaigns = data.campaigns;
                }
            ).catch(err => {
                this.$store.dispatch('showError', err);
            }).finally(() => {
                this.showLoaderCamping = false;
            })

        },
        getCampaignsTypes() {
            app.getUserCampaignsAllTypes().then(data => {
                this.campaignsFilters = data;
            }).catch(err => {
                this.$store.dispatch('showError', err);
            })
        },
        getCampaignsTypesForUser() {
            this.showLoaderCamping = true;
            app.getUserCampaignsForTypes(this.currentFilter, this.pagination).then(data => {
                this.pagination.totalCount = data.count;
                this.campaigns = data.campaigns;
            }).catch(err => {
                this.$store.dispatch('showError', err);
            }).finally(() => {
                this.showLoaderCamping = false;
            })
        },
        changeFilter(name) {
					if (this.currentFilter !== name) {
						this.pagination = {
							offset: 1,
							limit: this.pagination.limit,
						}
						this.currentFilter = name;
					}
        },
    }
};
</script>
